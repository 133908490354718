import React from 'react';
import { StructuredText } from 'react-datocms';

import { Container, Content } from './bodyStructuredText.styles';
import { BodyStructuredTextProps } from './bodyStructuredText.types';

export const BodyStructuredText = ({ content }: BodyStructuredTextProps) => {
  return (
    <Container>
      <Content>
        <StructuredText
          data={content}
          renderBlock={() => {
            return null;
          }}
        />
      </Content>
    </Container>
  );
};
