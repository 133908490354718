import styled from 'styled-components';

import {
  CONTAINER,
  getMediaQuery,
  getUnitAsPixels,
  getVerticalSpacingAsStyle,
  GRID,
  ScreenSize,
  VerticalSpacingType,
  VerticalSpacingVariant,
} from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const Container = styled.div`
  ${CONTAINER}
`;

export const Content = styled.div`
  ${GRID}
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING, {
    top: VerticalSpacingVariant.MEDIUM,
    bottom: VerticalSpacingVariant.MEDIUM,
  })}

  >p, >ol, >ul {
    ${getTypography(Type.BODY_REGULAR)}
    margin-bottom: ${getUnitAsPixels({ multiplier: 1 })};
    grid-column: span 4;
    line-height: 1.5;

    ${getMediaQuery(ScreenSize.MEDIUM)} {
      grid-column: 1 / span 12;
    }

    ${getMediaQuery(ScreenSize.LARGE)} {
      margin-bottom: ${getUnitAsPixels({ multiplier: 2 })};
      grid-column: 4 / span 6;
    }

    &:last-child {
      margin-bottom: 0;
    }

    strong {
      ${getTypography(Type.BODY_REGULAR_BOLD)}
    }

    em {
      background-color: ${({ theme }) => theme.background.secondary};
    }

    a {
      color: ${({ theme }) => theme.foreground.primary};
      text-decoration-color: ${({ theme }) => theme.foreground.tertiary};
      transition: text-decoration-color 0.3s;
      word-wrap: break-word;

      &:hover {
        text-decoration-color: ${({ theme }) => theme.foreground.primary};
      }
    }
  }

  > ol {
    padding-inline-start: ${getUnitAsPixels({ multiplier: 4 })};
    list-style: auto;

    > li {
      padding-inline-start: ${getUnitAsPixels()};
      margin-bottom: ${getUnitAsPixels({ multiplier: 1.5 })};

      &::marker {
        color: ${({ theme }) => theme.foreground.tertiary};
      }
    }
  }

  > ul {
    padding-inline-start: ${getUnitAsPixels({ multiplier: 4 })};
    list-style: disc;

    > li {
      padding-inline-start: ${getUnitAsPixels()};
      margin-bottom: ${getUnitAsPixels({ multiplier: 1.5 })};

      &::marker {
        color: ${({ theme }) => theme.foreground.tertiary};
      }
    }
  }

  > h1,
  > h2,
  > h3,
  > h4,
  > h4,
  > h5,
  > h6 {
    margin-bottom: ${getUnitAsPixels({ multiplier: 1 })};
    grid-column: span 4;

    ${getMediaQuery(ScreenSize.MEDIUM)} {
      grid-column: 1 / span 12;
    }

    ${getMediaQuery(ScreenSize.LARGE)} {
      margin-bottom: ${getUnitAsPixels({ multiplier: 2 })};
      grid-column: 4 / span 6;
    }
  }

  > h1 {
    ${getTypography(Type.HEADING_1)}
  }

  > h2 {
    ${getTypography(Type.HEADING_2)}
  }

  > h3 {
    ${getTypography(Type.HEADING_3)}
  }

  > h4,
  > h4,
  > h5,
  > h6 {
    ${getTypography(Type.BODY_REGULAR)}
  }
`;
