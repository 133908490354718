/* eslint no-param-reassign: "off" */
import get from 'lodash/get';

const createSchema = () => {
  return {
    '@context': 'http://schema.org',
  };
};

const appendImage = (obj: any, seo: any) => {
  const image1200x1200 = get(seo, 'image1200x1200.fixed.src');
  const image1200x900 = get(seo, 'image1200x900.fixed.src');
  const image1200x675 = get(seo, 'image1200x675.fixed.src');

  if (image1200x1200 && image1200x900 && image1200x675) {
    obj.image = [image1200x1200, image1200x900, image1200x675];
  }
};

const appendPublisherLogo = (obj: any, siteURL: any) => {
  if (siteURL) {
    obj.logo = {
      '@type': 'ImageObject',
      width: '600',
      height: '60',
      url: `${siteURL}/images/publisher-logo.png`,
    };
  }
};

const appendPublisher = (obj: any, siteName: any) => {
  if (siteName) {
    obj.publisher = {
      '@type': 'Organization',
      name: siteName,
    };

    appendPublisherLogo(obj.publisher, siteURL);
  }
};

const appendModified = (obj: any, pageModified: any) => {
  if (pageModified) {
    obj.dateModified = pageModified;
  }
};

const appendCreated = (obj: any, pageCreated: any) => {
  if (pageCreated) {
    obj.datePublished = pageCreated;
  }
};

const appendAuthors = (obj: any, siteName, siteURL) => {
  obj.author = [];

  obj.author.push({
    '@type': 'Organization',
    name: siteName,
    url: siteURL,
  });
};

const appendHeadline = (obj: any, headline: any) => {
  if (headline) {
    obj.headline = headline;
  }
};

const appendURL = (obj: any, url: string) => {
  obj.url = url;
};

const appendName = (obj: any, pageTitle: any) => {
  if (pageTitle) {
    obj.name = pageTitle;
  }
};

const appendDescription = (obj: any, pageDescription: any) => {
  if (pageDescription) {
    obj.description = pageDescription;
  }
};

const appendMainEntity = (obj: any, url: string) => {
  if (url) {
    obj.mainEntityOfPage = url;
  }
};

const appendLanguage = (obj: any, languageCode: any) => {
  if (languageCode) {
    obj.inLanguage = {
      '@type': 'Language',
      name: languageCode,
    };
  }
};

const appendType = (obj: any, type: any) => {
  if (type) {
    obj['@type'] = type;
  }
};

export const getSchema = (props: any) =>
  props.pageContext.pageType === 'blog_article'
    ? articleSchema(props)
    : pageSchema(props);

export const pageSchema = ({ settings, page }: any) => {
  if (page.seo && page.seo) {
    const schema = createSchema();

    appendType(schema, 'WebPage');
    appendLanguage(schema, page.locale);
    appendURL(schema, settings.siteUrl);
    appendName(schema, page.seo.title);
    appendDescription(schema, page.seo.description);
    appendCreated(schema, page.meta.firstPublishedAt);
    appendModified(schema, page.meta.updatedAt);
    appendImage(schema, page.seo);

    return schema;
  }

  return null;
};

export const articleSchema = ({ settings, site, page }: any) => {
  if (page.seo && page.seo) {
    const schema = createSchema();

    appendType(schema, 'BlogPosting');
    appendLanguage(schema, page.locale);
    appendURL(schema, settings.siteUrl);
    appendName(schema, page.seo.title);
    appendDescription(schema, page.seo.description);
    appendCreated(schema, page.meta.firstPublishedAt);
    appendModified(schema, page.meta.updatedAt);
    appendImage(schema, page.seo);

    appendMainEntity(schema, settings.siteUrl);
    appendAuthors(schema, site.name, settings.siteUrl);
    appendHeadline(schema, page.title);
    appendPublisher(schema, site.name);

    return schema;
  }

  return null;
};
